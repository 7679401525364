// 加载灰度资源的版本号，通常是当前latest + 1版本号
// const version = '<%= version %>'; // 占位符，平台控制
const version = '1.0.1';

// 灰度放量比例, 随机数上限为10000
// const sampleRatio = '<%= sampleRatio %>'; // 占位符，平台控制
const sampleRatio = '0';

// 不参与灰度的项目ID
// const whiteProjectIds = `<%= whiteProjectIds %>`.split(',');
const whiteProjectIds: string[] = ['518', '515', '80', '519', '517', '454', '501', '522'];

// 参与全量灰度的项目ID
// const blackProjectIds = `<%= blackProjectIds %>`.split(',');
const blackProjectIds: string[] = ['lgw'];

const projectId = document.currentScript?.getAttribute('project-id');
const isLocal = document.currentScript?.getAttribute('src').includes('./dist');

// 默认加载的线上URL
const latestUrl = isLocal ?
  './dist/runtime-v1.0.1.umd.js' :
  'https://lf-security.bytegoofy.com/obj/security-secsdk/runtime-stable.js';
// 灰度线上版本URL
const grayUrl = isLocal ?
  './dist/runtime-v1.0.1.umd.js' :
  `https://lf-security.bytegoofy.com/obj/security-secsdk-gray/runtime-v${version}.js`;

if (projectId) {
  // 灰度判断逻辑
  const isGray = () => {
    // 命中灰度白名单
    if (projectId && whiteProjectIds.includes(projectId)) {
      return false;
    }

    // 命中灰度黑名单
    if (projectId && blackProjectIds.includes(projectId)) {
      return true;
    }

    const selectors: { path: string; value: string; op: string; tagerProjectIds: string[] }[] = [
      {
        path: 'gfdatav1.envName',
        value: 'prod',
        op: '!==',
        tagerProjectIds: [],
      },
      {
        path: 'SSR_RENDER_DATA.envService',
        value: 'prod',
        op: '!==',
        tagerProjectIds: [],
      },
    ];
    const result: boolean[] = [];
    // 命中环境变量灰度
    for (const selecor of selectors) {
      const { path, value, op } = selecor;
      let cur: any = window;
      for (const key of path.split('.')) {
        if (cur) {
          cur = cur[key];
        }
      }
      // tagerProjectIds灰度项目黑名单
      if (typeof cur === 'undefined') {
        continue;
      }
      const isProjectGray = selecor.tagerProjectIds.length === 0 || selecor.tagerProjectIds.includes(projectId);
      if (op === '===') {
        result.push(isProjectGray && cur === value);
      } else if (op === '!==') {
        result.push(isProjectGray && cur !== value);
      }
    }
    // 命中灰度  或  未命中环境变量，使用随机数生成，判断命中灰度
    return (result.length !== 0 && result.some(v => v)) || Math.floor(Math.random() * 10000) < Number(sampleRatio);
  };

  // 切换资源加载URL
  const currentUrl = isGray() ? grayUrl : latestUrl;

  localStorage.setItem('web_runtime_switcher_isgray', String(isGray()));

  // 实际加载资源
  const nonce = document.currentScript?.nonce || '';

  // 缓存版本，区分灰度版本和线上
  const cacheKey = `web_runtime_switcher_${isGray() ? version : 'latest'}`;

  const nativeFetch = window.fetch;

  const report = (eventName, e?: any) => {
    // 异步上报策略初始化异常
    const NATIVE_NAVIGATOR = window.navigator;
    const NATIVE_SENDBEACON = NATIVE_NAVIGATOR.sendBeacon;
    const IS_SENDBEACON_SUPOORT = NATIVE_SENDBEACON && typeof NATIVE_SENDBEACON === 'function';
    const url = 'https://mon.zijieapi.com/monitor_browser/collect/batch/security/?bid=global';
    const reportString: any = {
      event_name: eventName,
      event_pageUrl: window.location.href,
      event_payload: '{}',
      fromStage: 'init',
      documentURL: window.location.href,
      is_gray: String(isGray()),
      sdkVersion: version,
    };
    if (e) {
      reportString.errorStack_name = e.name;
      reportString.errorStack_message = e.message;
      reportString.errorStack_detail = e.detail;
    }
    const currentData = {
      age: Date.now(),
      url: projectId,
      type: 'runtime_strategy',
      report: {
        reportString,
        reportInt: {
          event_timestamp: Date.now(),
        },
      },
      'user-agent': window.navigator.userAgent,
    };
    if (IS_SENDBEACON_SUPOORT) {
      const success = NATIVE_SENDBEACON.call(NATIVE_NAVIGATOR, url, JSON.stringify(currentData));
      if (!success) {
        nativeFetch(url, {
          method: 'post',
          body: JSON.stringify(currentData),
          headers: {
            'Content-Type': 'application/json',
          },
        });
      }
    } else {
      nativeFetch(url, {
        method: 'post',
        body: JSON.stringify(currentData),
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
  };

  // 异步加载，进行缓存
  fetch(currentUrl)
    .then(res => res.text())
    .then(text => {
      const setItem = (window as any).SDKNativeWebApi?.API_LOCALSTORAGE_SET;
      // 同步执行eval之后，setItem会被代理，这里需要使用代理前的setItem
      if (setItem) {
        setItem.fn.apply(localStorage, [cacheKey, text]);
      } else {
        localStorage.setItem(cacheKey, text);
      }
    });

  try {
    // 同步加载灰度
    if (localStorage.getItem(cacheKey)) {
      // 已有缓存，直接同步eval执行
      eval(localStorage.getItem(cacheKey) as string);
    } else {
      // 同步加载，立刻执行
      document.writeln(`<script src="${currentUrl}" nonce="${nonce}" project-id="${projectId}"></script>`);
    }
    // 添加千一采样
    if (Math.floor(Math.random() * 1000) < 1) {
      report('SWITCHER_LOAD_SUCCESS');
    }
  } catch (e) {
    report('SWITCHER_LOAD_ERROR', e);
  }
}
